
export default {

  methods: {

    calcCost(product) {
      
      let purchase_price = this.parseValue(product.purchase_price)
      let st = this.parseValue(product.st)
      let ipi = this.parseValue(product.ipi)
      let recomposicao_aliquota = this.parseValue(product.recomposicao_aliquota)
      let shipping = this.parseValue(product.shipping)

      let costPriceTaxesFactor = 1 + ((st + ipi + recomposicao_aliquota) / 100);
      
      let costPrice = purchase_price *  costPriceTaxesFactor + shipping;
      
      return costPrice

    },
    
    calcProfit(product) {
      
      let icms_credit = this.parseValue(product.icms_credit)
      let icms_debt = this.parseValue(product.icms_debt)
      let pis = this.parseValue(product.pis)
      let cofins = this.parseValue(product.cofins)
      let irpj = this.parseValue(product.irpj)
      let csll = this.parseValue(product.csll)
      let simples_nacional = this.parseValue(product.simples_nacional)
      let commission = this.parseValue(product.commission)
      let price_cash = this.parseValue(product.price_cash)

      let costPrice = this.calcCost(product)

      let icmsCreditValue = ((icms_credit) / 100) * costPrice; // todo frete conta??

      let sellPriceTaxesFactor = (icms_debt + pis + cofins + irpj + csll + simples_nacional + commission ) / 100; 
      
      let sellExpensesValue = price_cash * sellPriceTaxesFactor;

      let profit = 0;

      if (price_cash != 0) {

          profit = 100 * (1 - ((costPrice + sellExpensesValue - icmsCreditValue) / price_cash));

      }
      // console.log(profit)

      return profit
    },

    // calcIdealPrice

    calcPriceForward(product) {
      let price_cash = this.parseValue(product.price_cash)
      
      return price_cash / 0.85
    },

    parseValue(value) {
      
      if (value) {
        return parseFloat(value)
      }
      
      return 0
    }
  }
  
}