<template>
  <v-navigation-drawer
    clipped
    temporary
    app
    :permanent="drawer"
    right
    v-model="drawer"
    width="600px"
  >
    <v-card-title>Alterar custos</v-card-title>
    <v-card-text class="mb-6">
      <v-row>
        <v-col>
          <app-number-field label="P. Compra" v-model="form.purchase_price" @input="handleProductValuesChange()"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <app-number-field v-model="form.st" label="ST" :prefix="'%'" @input="handleProductValuesChange()"/>
        </v-col>
        <v-col>
          <app-number-field v-model="form.ipi" label="IPI" :prefix="'%'" @input="handleProductValuesChange()"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6"
          ><app-number-field
            v-model="form.recomposicao_aliquota"
            label="Recomp. de Aliquota"
            @input="handleProductValuesChange()"
        /></v-col>
      </v-row>
      <v-row>
        <v-col cols="6"
          ><app-number-field v-model="form.shipping" label="Frete" @input="handleProductValuesChange()"
        /></v-col>
      </v-row>

      <v-row>
        <v-col><app-number-field v-model="form.cost" label="Custo" readonly /></v-col>
      </v-row>

      <v-row>
        <v-col
          ><app-number-field
            v-model="form.icms_credit"
            label="ICMS Entrada"
            :prefix="'%'"
            @input="handleProductValuesChange()"
        /></v-col>
        <v-col
          ><app-number-field
            v-model="form.icms_debt"
            label="ICMS Saida"
            :prefix="'%'"
            @input="handleProductValuesChange()"
        /></v-col>
      </v-row>
      <v-row>
        <v-col
          ><app-number-field v-model="form.pis" label="PIS" :prefix="'%'" @input="handleProductValuesChange()"
        /></v-col>
        <v-col
          ><app-number-field v-model="form.cofins" label="COFINS" :prefix="'%'" @input="handleProductValuesChange()"
        /></v-col>
      </v-row>

      <v-row>
        <v-col
          ><app-number-field v-model="form.irpj" label="IRPJ" :prefix="'%'" @input="handleProductValuesChange()"
        /></v-col>
        <v-col
          ><app-number-field v-model="form.csll" label="CSLL" :prefix="'%'" @input="handleProductValuesChange()"
        /></v-col>
      </v-row>
      <v-row>
        <v-col
          ><app-number-field v-model="form.simples_nacional" label="Simples" :prefix="'%'" @input="handleProductValuesChange()"
        /></v-col>
      </v-row>
      <v-row>
        <v-col
          ><app-number-field
            v-model="form.commission"
            label="Comissão"
            :prefix="'%'"
            @input="handleProductValuesChange()"
        /></v-col>
      </v-row>
      <!-- <v-row>
        <v-col
          ><app-number-field
            v-model="form.desired_profit"
            label="Lucro Ideal"
            :prefix="'%'"
        /></v-col>
        <v-col
          ><app-number-field
            v-model="form.desired_profit"
            label="Preço Sugerido"
        /></v-col>
      </v-row> -->
      <v-row>
        <v-col
          ><app-number-field v-model="form.price_cash" label="Preço Av." @input="handleProductValuesChange(); handlePriceCashChange();"
        /></v-col>
        <v-col
          ><app-number-field v-model="form.price_forward" label="Preço Pz." readonly
        /></v-col>
      </v-row>
      <v-row>
        <v-col
          ><app-number-field
            v-model="form.profit"
            label="Lucro"
            :prefix="'%'"
            readonly
            :allowNegative="true"
        /></v-col>
      </v-row>
    </v-card-text>
    <template v-slot:append>
      <div class="pa-4" style="background-color: #f5f5f5">
        <v-btn
          class="text-capitalize mr-2"
          color="primary"
          @click="handleConfirm()"
          >Confirmar</v-btn
        >
        <v-btn @click="drawer = false" text class="text-capitalize"
          >Cancelar</v-btn
        >
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>

import ProductCalcsMixin from "@/mixins/product/ProductCalcsMixin";

export default {
  
  mixins : [ProductCalcsMixin],

  props: {
    value: {},
  },

  data() {
    return {
      drawer: false,

      form: {
        desired_profit: 0,
        irpj: 0,
        csll: 0,
        commission: 0,
        pis: 0,
        cofins: 0,
        cost: 0,
        icms_debt: 0,
        icms_credit: 0,
        shipping: 0,
        recomposicao_aliquota: 0,
        ipi: 0,
        st: 0,
        purchase_price: 0,
      },
    };
  },

  methods: {
    open() {
      this.setFormData();

      this.drawer = true;
    },

    setFormData() {
      this.form = JSON.parse(JSON.stringify(this.value));
    },

    handleConfirm() {
      this.$emit("input", this.form);
      this.drawer = false;
    },

    handleProductValuesChange() {
      this.form.cost = this.calcCost(this.form)
      this.form.profit = this.calcProfit(this.form)
    },

    handlePriceCashChange() {
      this.form.price_forward = this.calcPriceForward(this.form)
    },
  },
};
</script>

<style>
</style>