<template>
  <v-dialog persistent v-model="dialog" scrollable width="1200px">
    <v-card>
      <v-card-title
        class="pa-4 justify-space-between"
        style="background-color: rgb(245, 245, 245)"
        primary-title
      >
        {{ `${title} Produto` }}

        <v-btn
          @click="handleOpenProductMovementView()"
          color="primary"
          icon
          outlined
        >
          <app-icon>query_stats </app-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-4" ref="productCardText">
        <v-form class="my-6">
          <v-row class="d-flex align-center">
            <v-col cols="2">
              <h3>Dados do produto</h3>
            </v-col>
            <v-col>
              <app-text-field
                :rules="[rules.required]"
                v-model="form.name"
                label="Nome"
              />
            </v-col>
            <v-col class="d-flex justify-start" cols="3">
              <v-switch
                v-model="form.status"
                :false-value="0"
                :true-value="1"
                hide-details
                :label="status[form.status].text"
              />
            </v-col>
            <v-col offset="2" cols="7">
              <app-text-field v-model="form.short_name" label="Nome Curto" />
            </v-col>
            <v-col class="d-flex justify-start" cols="3">
              <v-switch
                v-model="form.allow_sell_stock_negative"
                hide-details
                label="Vender abaixo do estoque?"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col offset="2">
              <CategoriesSearch
                @clear="form.category_id = null"
                v-model="form.category"
              />
            </v-col>
            <v-col>
              <BrandsSearch
                @clear="form.brand_id = null"
                v-model="form.brand"
              />
            </v-col>
            <v-col>
              <SupplierSearch
                @clear="form.supplier_id = null"
                ref="SupplierSearch"
                v-model="form.supplier"
              />
            </v-col>
          </v-row>

          <!-- <h4 class="mt-10 mb-3">Dados do Adicionais</h4> -->
          <v-divider class="my-12" />
          <v-row>
            <v-col cols="2">
              <h3>Dados Adicionais</h3>
            </v-col>
            <v-col cols="4">
              <AppSelectUnities v-model="form.product_variants[0].unity" />
            </v-col>
            <v-col cols="2">
              <app-text-field
                v-model="form.product_variants[0].manufacturer_id"
                label="Código Fabricante"
              />
            </v-col>
            <v-col cols="2">
              <app-text-field
                v-model="form.product_variants[0].gtin"
                label="GTIN"
              />
            </v-col>
            <v-col cols="2">
              <app-text-field
                v-model="form.product_variants[0].external_id"
                label="Código Externo"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col offset="2" cols="2">
              <app-number-field
                v-model="form.product_variants[0].weight_gross"
                label="Peso Bruto"
              />
            </v-col>
            <v-col cols="2">
              <app-number-field
                v-model="form.product_variants[0].weight_net"
                label="Peso Liquido"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" offset="2">
              <app-number-field
                v-model="form.product_variants[0].purchase_multiple"
                label="Múltiplo de Compra"
              />
            </v-col>

            <v-col cols="2">
              <app-number-field
                v-model="form.product_variants[0].purchase_factor"
                label="Fator de Compra"
              />
            </v-col>
            <v-col cols="2">
              <app-number-field
                v-model="form.product_variants[0].sell_multiple"
                label="Múltiplo de Venda"
              />
            </v-col>
            <v-col cols="2">
              <app-number-field
                v-model="form.product_variants[0].packaging_quantity"
                label="Qtd. na embalagem"
              />
            </v-col>
            <v-col cols="2">
              <app-text-field
                v-model="form.product_variants[0].packaging_gtin"
                label="GTIN Embalagem"
              />
            </v-col>
          </v-row>
          <v-divider class="my-12" />
          <!-- <h4 class="mt-10 mb-3">Fiscal</h4> -->
          <v-row>
            <v-col cols="2"><h3>Fiscal</h3></v-col>
            <v-col cols="4">
              <ClassificacaoFiscalSelect v-model="form.classificacao_fiscal" />
            </v-col>
            <v-col cols="2">
              <app-text-field v-model="form.ncm" label="NCM" />
            </v-col>
            <v-col cols="2">
              <app-text-field v-model="form.cest" label="CEST" />
            </v-col>
            <v-col cols="2">
              <app-text-field v-model="form.origem" label="Origem" />
            </v-col>
          </v-row>
          <v-divider class="my-12" />

          <v-row class="d-flex align-center">
            <v-col cols="2"><h4>Preço</h4></v-col>
            <v-col cols="2">
              <app-number-field
                v-model="form.product_variants[0].purchase_price"
                label="Preço de Compra"
                @input="handleProductValuesChange()"
              />
            </v-col>
            <v-col cols="2">
              <app-number-field
                v-model="form.product_variants[0].cost"
                label="Custo"
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" offset="2">
              <app-number-field
                v-model="form.product_variants[0].price_cash"
                label="Preço AV"
                @input="
                  handleProductValuesChange();
                  handlePriceCashChange();
                "
              />
            </v-col>
            <v-col cols="2">
              <app-number-field
                v-model="form.product_variants[0].price_forward"
                label="Preço PZ"
                readonly
              />
            </v-col>
            <v-col cols="2">
              <app-number-field
                v-model="form.product_variants[0].profit"
                label="Lucro"
                :prefix="' % '"
                :allowNegative="true"
              />
            </v-col>
            <v-col cols="2">
              <v-btn
                @click="openProductCostsFormDrawer()"
                text
                class="text-capitalize ml-5"
                color="primary"
              >
                editar
              </v-btn>
            </v-col>
          </v-row>

          <v-divider class="my-12" />

          <v-row>
            <v-col cols="2">
              <h4>Estoques</h4>
            </v-col>
            <v-col>
              <template
                v-for="(productCompany, index) of form.product_variants[0]
                  .product_companies"
              >
                <v-row :key="index">
                  <v-col cols="1">
                    <v-chip small>
                      {{ productCompany.company.short_name }}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">
                    <app-number-field
                      v-model="productCompany.minimum_stock"
                      label="Estoque min."
                    />
                  </v-col>
                  <v-col cols="2">
                    <app-number-field
                      v-model="productCompany.maximum_stock"
                      label="Estoque max."
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-switch
                      class="mt-0"
                      v-model="productCompany.can_sell"
                      :false-value="0"
                      :true-value="1"
                      hide-details="true"
                      label="Pode vender"
                    ></v-switch>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <h4>Observações</h4>
            </v-col>
            <v-col cols="6">
              <v-textarea
                v-model="form.observations"
                outlined
                auto-grow
                rows="3"
              />
            </v-col>
          </v-row>

          <ProductCostsFormDrawer
            ref="ProductCostsFormDrawer"
            v-model="form.product_variants[0]"
          />
        </v-form>
      </v-card-text>

      <v-card-actions
        class="d-flex justify-end pa-4"
        style="background-color: rgb(245, 245, 245)"
      >
        <v-btn
          @click="dialog = false"
          class="text-capitalize"
          text
          color="secondary"
        >
          Cancelar
        </v-btn>
        <v-btn @click="handleSave()" class="text-capitalize" color="primary">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
    <ProductMovementView ref="ProductMovementView" />
  </v-dialog>
</template>

<script>
import BrandsSearch from "@/components/brands/ui/BrandsSearch.vue";
import SupplierSearch from "@/components/suppliers/ui/SupplierSearch.vue";
import CategoriesSearch from "@/components/categories/ui/CategoriesSearch.vue";
import ClassificacaoFiscalSelect from "@/components/product/ui/ClassificacaoFiscalSelect";
import AppSelectUnities from "@/components/app/sections/AppSelectUnities.vue";
import ProductCostsFormDrawer from "@/components/product/forms/ProductCostsFormDrawer.vue";
import ProductCalcsMixin from "@/mixins/product/ProductCalcsMixin";
import ProductMovementView from "@/components/product/ui/ProductMovementView.vue";
export default {
  components: {
    BrandsSearch,
    SupplierSearch,
    CategoriesSearch,
    ClassificacaoFiscalSelect,
    AppSelectUnities,
    ProductCostsFormDrawer,
    ProductMovementView,
  },

  mixins: [ProductCalcsMixin],

  data: () => ({
    companies: [],
    dialog: false,
    status: {
      1: {
        text: "Cadastro Ativo",
      },

      0: {
        text: "Cadastro Inativo",
      },
    },

    valid: null,

    form: {
      status: 1,
    },

    baseForm: {
      brand: {},
      supplier: {},
      category: {},

      name: null,
      short_name: null,
      code: 0,
      observations: null,

      ncm: null,
      cest: null,
      origem: null,
      classificacao_fiscal: "00",
      status: 1,

      product_variants: [
        {
          unity: {
            id: 1, // todo make this dinamicaly
            code: 12,
            name: "Unidade",
            symbol: "UN",
            status: 1,
          },
          webcom_id: null,
          external_id: null,
          manufacturer_id: null,
          weight_gross: 0,
          weight_net: 0,
          sell_multiple: 1,
          purchase_multiple: 1,
          purchase_factor: 1,
          gtin: null,
          packaging_gtin: null,
          packaging_quantity: 0,
          purchase_price: 0,
          st: 0,
          ipi: 0,
          recomposicao_aliquota: 0,
          shipping: 0,
          shipping_percent: 0,
          cost: 0,
          icms_debt: 0,
          icms_credit: 0,
          pis: 0,
          cofins: 0,
          irpj: 0,
          csll: 0,
          simples_nacional: 0,
          price_cash: 0,
          price_forward: 0,
          desired_profit: 0,
          desired_price_cash: 0,
          profit: 0,
          status: 1,

          product_companies: [],
        },
      ],
    },

    rules: {
      required: (value) => !!value || "Required.",
    },
  }),

  created() {
    this.reset(); // todo fix gambiarra
  },

  computed: {
    isInsert() {
      return !this.form.id;
    },
    title() {
      return this.isInsert ? "Novo" : "Atualizar";
    },
  },

  methods: {
    async open(id = null, data = null) {
      this.reset(); // todo fix gambiarra

      await this.getCompanies();

      this.reset();

      if (id) {
        await this.showProduct(id);
      }

      if (data) {
        this.fillData(data);
      }

      this.dialog = true;

      this.$nextTick(() => {
        this.$refs.productCardText.scrollTop = 0;
      });
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));

      if (this.isInsert) {
        this.setProductCompanies();
      }
    },

    fillData(data) {
      this.form.name = data.name;
      this.form.short_name = data.name
        .split(" ")
        .slice(0, 3)
        .join(" ");

      this.form.ncm = data.ncm;
      this.form.cest = data.cest;
      this.form.origem = data.origem;
      this.form.allow_sell_stock_negative = data.allow_sell_stock_negative;

      this.form.product_variants[0].gtin = data.gtin;
      this.form.product_variants[0].manufacturer_id = data.manufacturer_id;

      this.form.product_variants[0].purchase_price = data.price;
      this.form.product_variants[0].st = data.icms_st_percent;
      this.form.product_variants[0].ipi = data.ipi_aliquot;
      this.form.supplier = data.supplier;
    },

    async getCompanies() {
      await this.$http
        .index("/company/company", { status: 1, stock_status: 1 })
        .then((response) => {
          this.companies = response.companies;
        });
    },

    setProductCompanies() {
      this.companies.forEach((company) => {
        this.form.product_variants[0].product_companies.push({
          company: company,
          company_id: company.id,
          webcom_id: company.webcom_id,
          cst: null,
          csosn: null,
          icms_regime: "icms",
          purchase_price: 0,
          st: 0,
          ipi: 0,
          recomposicao_aliquota: 0,
          shipping: 0,
          shipping_percent: 0,
          cost: 0,
          icms_debt: 0,
          icms_credit: 0,
          pis: 0,
          cofins: 0,
          irpj: 0,
          csll: 0,
          simples_nacional: 0,
          price_cash: 0,
          price_forward: 0,
          desired_profit: 0,
          desired_price_cash: 0,
          can_sell: 1,
          moviment_stock: 1,
          minimum_stock: 0,
          maximum_stock: 0,
        });
      });
    },

    handleProductValuesChange() {
      this.form.product_variants[0].cost = this.calcCost(
        this.form.product_variants[0]
      );
      this.form.product_variants[0].profit = this.calcProfit(
        this.form.product_variants[0]
      );
    },

    handlePriceCashChange() {
      this.form.product_variants[0].price_forward = this.calcPriceForward(
        this.form.product_variants[0]
      );
    },

    async showProduct(id) {
      this.$loading.start();
      await this.$http
        .$get(`product/product/${id}`)
        .then((response) => {
          this.form = response.product;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
          this.dialog = false;
        });
    },

    handleSave() {
      this.$loading.start();
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$http
        .store("product/product", this.getForm())
        .then((response) => {
          this.processSaved(response);
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    update() {
      this.$http
        .update("product/product", this.form.id, this.getForm())
        .then((response) => {
          this.processSaved(response);
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved(product) {
      this.$emit("store", product);
      this.$loading.finish();
      this.dialog = false;
    },

    openProductCostsFormDrawer() {
      this.$refs.ProductCostsFormDrawer.open();
    },

    newProduct(product) {
      this.dialog = true;
      this.form = JSON.parse(JSON.stringify(product));
    },
    handleOpenProductMovementView() {
      this.$refs.ProductMovementView.open(this.form.id);
    },
    getForm() {
      return {
        name: this.form.name,
        short_name: this.form.short_name,
        code: this.form.code,
        ncm: this.form.ncm,
        cest: this.form.cest,
        origem: this.form.origem,
        classificacao_fiscal: this.form.classificacao_fiscal,
        observations: this.form.observations,
        allow_sell_stock_negative: this.form.allow_sell_stock_negative,
        status: this.form.status,
        category: this.form.category ? { id: this.form.category.id } : null,
        brand: this.form.brand ? { id: this.form.brand.id } : null,
        supplier: this.form.supplier ? { id: this.form.supplier.id } : null,
        product_variants: this.mapProductVariants(),
      };
    },
    mapProductVariants() {
      return this.form.product_variants.map((variant) => {
        return {
          id: variant.id || null,
          product_id: variant.product_id || null,
          unity: variant.unity ? { id: variant.unity.id } : null,
          code: variant.code,
          webcom_id: variant.webcom_id,
          external_id: variant.external_id,
          manufacturer_id: variant.manufacturer_id,
          weight_gross: variant.weight_gross,
          weight_net: variant.weight_net,
          sell_multiple: variant.sell_multiple,
          purchase_multiple: variant.purchase_multiple,
          purchase_factor: variant.purchase_factor,
          gtin: variant.gtin,
          packaging_gtin: variant.packaging_gtin,
          packaging_quantity: variant.packaging_quantity,
          purchase_price: variant.purchase_price,
          st: variant.st,
          ipi: variant.ipi,
          recomposicao_aliquota: variant.recomposicao_aliquota,
          shipping: variant.shipping,
          shipping_percent: variant.shipping_percent,
          cost: variant.cost,
          icms_debt: variant.icms_debt,
          icms_credit: variant.icms_credit,
          pis: variant.pis,
          cofins: variant.cofins,
          irpj: variant.irpj,
          csll: variant.csll,
          simples_nacional: variant.simples_nacional,
          commission: variant.commission,
          price_cash: variant.price_cash,
          price_forward: variant.price_forward,
          desired_profit: variant.desired_profit,
          desired_price_cash: variant.desired_price_cash,
          profit: variant.profit,
          status: variant.status,

          product_companies: this.mapProductCompanies(variant),
        };
      });
    },

    mapProductCompanies(variant) {
      return variant.product_companies.map((company) => {
        return {
          id: company.id || null,
          product_id: company.product_id || null,
          product_variant_id: company.product_variant_id || null,
          company_id: company.company_id || null,
          cst: company.cst,
          csosn: company.csosn,
          icms_regime: company.icms_regime,
          purchase_price: company.purchase_price,
          st: company.st,
          ipi: company.ipi,
          recomposicao_aliquota: company.recomposicao_aliquota,
          shipping: company.shipping,
          shipping_percent: company.shipping_percent,
          cost: company.cost,
          icms_debt: company.icms_debt,
          icms_credit: company.icms_credit,
          pis: company.pis,
          cofins: company.cofins,
          irpj: company.irpj,
          csll: company.csll,
          simples_nacional: company.simples_nacional,
          commission: company.commission,
          price_cash: company.price_cash,
          price_forward: company.price_forward,
          desired_profit: company.desired_profit,
          desired_price_cash: company.desired_price_cash,
          profit: company.profit,
          can_sell: company.can_sell,
          moves_stock: company.moves_stock,
          minimum_stock: company.minimum_stock,
          maximum_stock: company.maximum_stock,
          status: company.status,
        };
      });
    },
  },
};
</script>

<style></style>
